import { createRequestConfig } from '../../createRequestConfig';

export const verifyRecaptchaUsingPost = createRequestConfig<
  {
    requestBody: VerifyRecaptchaReq;
  },
  VerifyRecaptchaRes
>('verifyRecaptchaUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/captcha/verify-recaptcha`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export interface VerifyRecaptchaReq {
  captchaToken?: string;
}

export interface VerifyRecaptchaRes {
  data?: { [key: string]: any };
  message?: string;
}
