import { ToastAttributes } from '@components/toast';
import { useSnackbar } from 'notistack';
import { ReactNode } from 'react';
type UseToastState = ToastAttributes;
interface UseToastReturnType {
  setToast: (toast: UseToastState) => void;
  successToast: (_toast: GenericUseToastType) => void;
  errorToast: (_toast: GenericUseToastType) => void;
  warningToast: (_toast: GenericUseToastType) => void;
}
interface GenericUseToastType {
  persist?: boolean;
  autoHideDuration?: number;
  message: string;
  title?: string;
  actionIcon?: ReactNode;
  onClose?: () => void;
}

/**
 * A custom hook to config the Toast component.
 * NOTE: Use along with the Toast component available at 'src/components/atoms'
 */
const useToast = (): UseToastReturnType => {
  const {
    enqueueSnackbar
  } = useSnackbar();
  const setToast = (_toast: UseToastState) => {
    enqueueSnackbar({
      variant: _toast.severity,
      persist: _toast.persist,
      autoHideDuration: _toast.autoHideDuration,
      message: _toast.message,
      title: _toast.alertTitle,
      actionIcon: _toast.actionIcon,
      onClose: _toast.onClosedHandler
    });
  };
  const successToast = (_toast: GenericUseToastType) => {
    setToast({
      severity: 'success',
      message: _toast.message
    });
  };
  const errorToast = (_toast: GenericUseToastType) => {
    setToast({
      severity: 'error',
      message: _toast.message
    });
  };
  const warningToast = (_toast: GenericUseToastType) => {
    setToast({
      severity: 'warning',
      message: _toast.message
    });
  };
  return {
    setToast,
    successToast,
    errorToast,
    warningToast
  };
};
export default useToast;