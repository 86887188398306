'use client';

import React, { useEffect, useRef, useState } from 'react';
import Head from 'next/head';
import { useRouter, useSearchParams } from 'next/navigation';
import ReCAPTCHA from 'react-google-recaptcha';
import { Button, CircularProgress } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useCheckPermission } from '@hooks/useCheckPermission';
import useCheckCookies from '@src/hooks/useCheckCookies';
import { verifyRecaptchaUsingPost } from '@src/service/apis/generated/captcha-controller';
import { getCurrentUserUsingGet } from '@src/service/apis/generatedv3/admin-portal-user-controller';
import { useRequest } from '@src/service/useRequest';
import useUserStore from '@stores/user';
import { isEmpty } from '@src/utils/common';
import { RECAPTCHA_SITE_KEY, API_HOST, REDIRECT_URI } from '../../config';
import useToast from '@src/hooks/useToast';
import { DEFAULT_ERROR_MESSAGE } from '@src/constants/common';
import COLORS from '@src/constants/colors';
const useStyles = makeStyles(() => ({
  loginContainer: {
    position: 'absolute',
    top: 0,
    display: 'flex',
    width: '100vw',
    height: '100vh'
  },
  nanovestLogo: {
    marginBottom: 24,
    width: '30%'
  },
  leftWrapper: {
    width: '55%',
    height: '100%',
    backgroundImage: 'url("/assets/images/login_screen_bg.webp")',
    backgroundColor: COLORS.slate[700],
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  rightWrapper: {
    width: '45%',
    height: '100%',
    backgroundColor: COLORS.slate[700],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  loginBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '65%',
    padding: '48px',
    background: COLORS.slate[900],
    boxShadow: '0px 5px 18px rgba(40, 41, 61, 0.07)',
    borderRadius: 30
  },
  headerText: {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 31,
    lineHeight: '37px',
    color: COLORS.white,
    marginTop: -8
  },
  button: {
    background: `linear-gradient(92.15deg, ${COLORS.purple[400]} 0%, ${COLORS.blue[400]} 96.87%)`,
    borderRadius: 6,
    height: '50px',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    lineHeight: '28px',
    textAlign: 'center',
    color: COLORS.white,
    width: '100%',
    marginTop: 8,
    textTransform: 'none'
  },
  errorText: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '18px',
    color: COLORS.rose[500],
    textAlign: 'start',
    width: '100%'
  },
  loader: {
    color: 'white',
    width: 75,
    height: 75
  },
  wrapper: {
    minWidth: '100vw',
    minHeight: '100vh',
    backgroundColor: COLORS.slate[900],
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));
const Home: React.FC = () => {
  const classes = useStyles();
  const {
    setToast
  } = useToast();
  const router = useRouter();
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const {
    getNextRoute,
    getUserPermissions
  } = useCheckPermission();
  const {
    isLoginTokenAvailable,
    isRecaptchaDisabled
  } = useCheckCookies();
  const searchParams = useSearchParams();
  const redirect = searchParams.get('redirect');

  // NB: Global state values and functions.
  const userState = useUserStore(state => state.user);
  const setUserState = useUserStore(state => state.setUserData);
  const [isUserLoading, setUserLoading] = useState<boolean>(false);
  const [getCurrentUserData] = useRequest(getCurrentUserUsingGet, {
    onSuccess: data => {
      const {
        roles: userRoles
      } = data;
      setUserState(data);
      const {
        userRoleList,
        userAcls
      } = getUserPermissions(userRoles);
      router.push(getNextRoute(userAcls, userRoleList));
    },
    onFail: e => {
      setToast({
        severity: 'error',
        message: e?.message || DEFAULT_ERROR_MESSAGE
      });
      router.push('/logout');
    }
  });
  const handleGoogleSignIn = () => {
    setUserLoading(true);
    window.location.href = `${API_HOST}/admin-portal/oauth2/authorize/google?redirect_uri=${REDIRECT_URI}`;
  };
  const [validateRecaptcha] = useRequest(verifyRecaptchaUsingPost, {
    onSuccess: res => {
      if (res?.message === 'OK') {
        setTimeout(() => {
          recaptchaRef.current?.reset();
          handleGoogleSignIn();
        }, 500);
      } else {
        setTimeout(() => {
          recaptchaRef.current?.reset();
          throw new Error(res?.message);
        }, 500);
      }
    },
    onFail: () => {
      setTimeout(() => {
        recaptchaRef.current?.reset();
      }, 500);
    }
  });
  const onLoginWithRecaptcha = async () => {
    if (process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && isRecaptchaDisabled || isEmpty(RECAPTCHA_SITE_KEY)) {
      handleGoogleSignIn();
      return;
    }
    if (!recaptchaRef.current) return;
    const captchaToken = await recaptchaRef.current?.executeAsync();
    if (!captchaToken) return;
    validateRecaptcha({
      requestBody: {
        captchaToken: captchaToken
      }
    });
  };
  useEffect(() => {
    // NB: To handle redirect back to admin-portal system,
    // If the value of isLoginTokenAvailable is true, then user has proper access token.
    if (isLoginTokenAvailable) {
      setUserLoading(true);
      if (!userState) {
        getCurrentUserData(null);
      }
    }
  }, []);
  return <>
      <Head data-sentry-element="Head" data-sentry-source-file="page.tsx">
        <title>Nanovest Admin Portal</title>
      </Head>
      <div className={classes.loginContainer} data-testid="home-page-main-wrapper">
        <div className={classes.leftWrapper} />
        <div className={classes.rightWrapper}>
          <img src="https://storage.googleapis.com/nanovest-dev/auth0/nanovest_logo.svg" className={classes.nanovestLogo} alt="" id="nanovest-logo" data-testid="nanovest-logo" />
          <div className={classes.loginBox} id="login-form-wrapper" data-testid="login-form-wrapper">
            <p className={classes.headerText} id="header-text" data-testid="header-text">
              {isUserLoading ? 'Signing in ' : 'Sign in to your account'}
            </p>
            {!isRecaptchaDisabled && !isEmpty(RECAPTCHA_SITE_KEY) && isEmpty(redirect) && <ReCAPTCHA ref={recaptchaRef} size="invisible" sitekey={RECAPTCHA_SITE_KEY} data-testid="recaptcha-ref" />}
            {!isUserLoading && isEmpty(redirect) && <>
                <Button type="submit" variant="contained" color="primary" className={classes.button} onClick={onLoginWithRecaptcha} id="signIn-btn" data-testid="signIn-btn">
                  Sign in
                </Button>
              </>}
            {(isUserLoading || redirect === 'success') && <CircularProgress id="isLoading" data-testid="isLoading-circular" className={classes.loader} />}
          </div>
        </div>
      </div>
    </>;
};
export default Home;