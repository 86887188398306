import { createRequestConfig } from '@src/service/createRequestConfig';

export const createUserUsingPost = createRequestConfig<{
  requestBody: CreateUserRequest;
}>('createUserUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/user/admin-user`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const getAdminTokenByResourceOwnerRequestUsingPost = createRequestConfig<
  {
    requestBody: CreateResourceOwnerRequest;
  },
  Auth0TokenResponse
>('getAdminTokenByResourceOwnerRequestUsingPost', ({ requestBody }) => ({
  url: `/admin-portal/v1/user/admin-token`,
  method: 'POST',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const getAdminUserListUsingGet = createRequestConfig<
  {
    limit?: number;
    page?: number;
  },
  ListAdminPortalUserResp[]
>('getAdminUserListUsingGet', ({ page, limit }) => ({
  url: `/admin-portal/v1/user/admin-user/list`,
  method: 'GET',
  params: {
    page,
    limit,
  },
}));

export const getCurrentUserUsingGet = createRequestConfig<
  undefined,
  ListAdminPortalUserResp
>('getCurrentUserUsingGet', () => ({
  url: `/admin-portal/v1/user/admin-user`,
  method: 'GET',
}));

export const getMappedUsersUsingPost = createRequestConfig<
  {
    adminSortBy?: keyof typeof GetMappedUsersUsingPostAdminSortBy;
    page: number;
    requestBody?: UserRequestFilter;
    size: number;
  },
  PaginationResponseListAdminPortalUserResp
>('getMappedUsersUsingPost', ({ page, size, adminSortBy, requestBody }) => ({
  url: `/admin-portal/v1/user/admin-user/list`,
  method: 'POST',
  data: requestBody,
  params: {
    page,
    size,
    adminSortBy,
  },
  headers: { 'Content-Type': 'application/json' },
}));

export const getRoleListUsingGet = createRequestConfig<
  undefined,
  (keyof typeof Enum2)[]
>('getRoleListUsingGet', () => ({
  url: `/admin-portal/v1/user/admin-user/role/list`,
  method: 'GET',
}));

export const getUserByIdUsingGet = createRequestConfig<
  {
    id: number;
  },
  ListAdminPortalUserResp
>('getUserByIdUsingGet', ({ id }) => ({
  url: `/admin-portal/v1/user/admin-user/${id}`,
  method: 'GET',
}));

export const retrieveUserRoleUsingGet = createRequestConfig<
  {
    email: string;
  },
  keyof typeof Enum1
>('retrieveUserRoleUsingGet', ({ email }) => ({
  url: `/admin-portal/v1/user/admin-user/roles`,
  method: 'GET',
  params: {
    email,
  },
}));

export const setAdminUserStatusActiveUsingPut = createRequestConfig<{
  requestBody: ActiveAdminPortalUserStatusReq;
}>('setAdminUserStatusActiveUsingPut', ({ requestBody }) => ({
  url: `/admin-portal/v1/user/admin-user/activation`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updateAdminUserRoleUsingPut = createRequestConfig<{
  requestBody: UpdateAdminPortalUserRoleReq;
}>('updateAdminUserRoleUsingPut', ({ requestBody }) => ({
  url: `/admin-portal/v1/user/admin-user/role`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export const updateAdminUserStatusUsingPut = createRequestConfig<{
  requestBody: UpdateAdminPortalUserStatusReq;
}>('updateAdminUserStatusUsingPut', ({ requestBody }) => ({
  url: `/admin-portal/v1/user/admin-user/status`,
  method: 'PUT',
  data: requestBody,
  headers: { 'Content-Type': 'application/json' },
}));

export interface ActiveAdminPortalUserStatusReq {
  email?: string;
}

export interface Auth0TokenResponse {
  access_token?: string;
  'admin-portal-access-token'?: string;
  id_token?: string;
}

export interface CreateResourceOwnerRequest {
  password: string;
  username: string;
}

export interface CreateUserRequest {
  adminRole?: keyof typeof CreateUserRequestAdminRole;
  emailAddress: string;
  fullName: string;
  roles?: number[];
  userName: string;
}

export enum CreateUserRequestAdminRole {
  'SUPER_ADMIN' = 'SUPER_ADMIN',
  'SUPPORT_ADMIN' = 'SUPPORT_ADMIN',
  'SPECIALIST' = 'SPECIALIST',
  'KYC_OPS' = 'KYC_OPS',
  'CUSTOMER_SERVICE_OPS' = 'CUSTOMER_SERVICE_OPS',
  'FINANCE_OPS' = 'FINANCE_OPS',
  'PRODUCT_BUSINESS_OPS' = 'PRODUCT_BUSINESS_OPS',
  'FINANCE_ADMIN' = 'FINANCE_ADMIN',
  'CUSTOMER_OPS_SPV' = 'CUSTOMER_OPS_SPV',
  'TECH_TEAM' = 'TECH_TEAM',
  'GS_PRODUCT_TEAM' = 'GS_PRODUCT_TEAM',
  'SALES_KYC' = 'SALES_KYC',
  'KYC_SPV' = 'KYC_SPV',
}

export enum Enum1 {
  'SUPER_ADMIN' = 'SUPER_ADMIN',
  'SUPPORT_ADMIN' = 'SUPPORT_ADMIN',
  'SPECIALIST' = 'SPECIALIST',
  'KYC_OPS' = 'KYC_OPS',
  'CUSTOMER_SERVICE_OPS' = 'CUSTOMER_SERVICE_OPS',
  'FINANCE_OPS' = 'FINANCE_OPS',
  'PRODUCT_BUSINESS_OPS' = 'PRODUCT_BUSINESS_OPS',
  'FINANCE_ADMIN' = 'FINANCE_ADMIN',
  'CUSTOMER_OPS_SPV' = 'CUSTOMER_OPS_SPV',
  'TECH_TEAM' = 'TECH_TEAM',
  'GS_PRODUCT_TEAM' = 'GS_PRODUCT_TEAM',
  'SALES_KYC' = 'SALES_KYC',
  'KYC_SPV' = 'KYC_SPV',
}

export enum Enum2 {
  'SUPER_ADMIN' = 'SUPER_ADMIN',
  'SUPPORT_ADMIN' = 'SUPPORT_ADMIN',
  'SPECIALIST' = 'SPECIALIST',
  'KYC_OPS' = 'KYC_OPS',
  'CUSTOMER_SERVICE_OPS' = 'CUSTOMER_SERVICE_OPS',
  'FINANCE_OPS' = 'FINANCE_OPS',
  'PRODUCT_BUSINESS_OPS' = 'PRODUCT_BUSINESS_OPS',
  'FINANCE_ADMIN' = 'FINANCE_ADMIN',
  'CUSTOMER_OPS_SPV' = 'CUSTOMER_OPS_SPV',
  'TECH_TEAM' = 'TECH_TEAM',
  'GS_PRODUCT_TEAM' = 'GS_PRODUCT_TEAM',
  'SALES_KYC' = 'SALES_KYC',
  'KYC_SPV' = 'KYC_SPV',
}

export interface EnumDto {
  id?: string;
  name?: string;
}

export enum GetMappedUsersUsingPostAdminSortBy {
  'CREATED_AT_ASC' = 'CREATED_AT_ASC',
  'EMAIL_ASC' = 'EMAIL_ASC',
  'CREATED_AT_DESC' = 'CREATED_AT_DESC',
  'EMAIL_DESC' = 'EMAIL_DESC',
}

export interface ListAdminPortalUserResp {
  email?: string;
  fullName?: string;
  id?: number;
  role?: keyof typeof ListAdminPortalUserRespRole;
  roles?: RoleResponseDto[];
  status?: keyof typeof ListAdminPortalUserRespStatus;
  username?: string;
}

export enum ListAdminPortalUserRespRole {
  'SUPER_ADMIN' = 'SUPER_ADMIN',
  'SUPPORT_ADMIN' = 'SUPPORT_ADMIN',
  'SPECIALIST' = 'SPECIALIST',
  'KYC_OPS' = 'KYC_OPS',
  'CUSTOMER_SERVICE_OPS' = 'CUSTOMER_SERVICE_OPS',
  'FINANCE_OPS' = 'FINANCE_OPS',
  'PRODUCT_BUSINESS_OPS' = 'PRODUCT_BUSINESS_OPS',
  'FINANCE_ADMIN' = 'FINANCE_ADMIN',
  'CUSTOMER_OPS_SPV' = 'CUSTOMER_OPS_SPV',
  'TECH_TEAM' = 'TECH_TEAM',
  'GS_PRODUCT_TEAM' = 'GS_PRODUCT_TEAM',
  'SALES_KYC' = 'SALES_KYC',
  'KYC_SPV' = 'KYC_SPV',
}

export enum ListAdminPortalUserRespStatus {
  'PENDING' = 'PENDING',
  'ACTIVE' = 'ACTIVE',
  'LOCKED' = 'LOCKED',
}

export interface PaginationResponseListAdminPortalUserResp {
  items?: ListAdminPortalUserResp[];
  totalElements?: number;
  totalPages?: number;
}

export interface RoleResponseDto {
  description?: string;
  id?: number;
  name?: string;
  permissions?: { [key: string]: EnumDto[] };
}

export interface UpdateAdminPortalUserRoleReq {
  adminRole?: keyof typeof UpdateAdminPortalUserRoleReqAdminRole;
  id: number;
  reason: string;
  roles?: number[];
}

export enum UpdateAdminPortalUserRoleReqAdminRole {
  'SUPER_ADMIN' = 'SUPER_ADMIN',
  'SUPPORT_ADMIN' = 'SUPPORT_ADMIN',
  'SPECIALIST' = 'SPECIALIST',
  'KYC_OPS' = 'KYC_OPS',
  'CUSTOMER_SERVICE_OPS' = 'CUSTOMER_SERVICE_OPS',
  'FINANCE_OPS' = 'FINANCE_OPS',
  'PRODUCT_BUSINESS_OPS' = 'PRODUCT_BUSINESS_OPS',
  'FINANCE_ADMIN' = 'FINANCE_ADMIN',
  'CUSTOMER_OPS_SPV' = 'CUSTOMER_OPS_SPV',
  'TECH_TEAM' = 'TECH_TEAM',
  'GS_PRODUCT_TEAM' = 'GS_PRODUCT_TEAM',
  'SALES_KYC' = 'SALES_KYC',
  'KYC_SPV' = 'KYC_SPV',
}

export interface UpdateAdminPortalUserStatusReq {
  id: number;
  reason?: string;
  status: keyof typeof UpdateAdminPortalUserStatusReqStatus;
}

export enum UpdateAdminPortalUserStatusReqStatus {
  'PENDING' = 'PENDING',
  'ACTIVE' = 'ACTIVE',
  'LOCKED' = 'LOCKED',
}

export interface UserRequestFilter {
  name?: string;
  roleId?: number;
}
